(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"));
	else if(typeof define === 'function' && define.amd)
		define("rb_wixui.thunderbolt[DatePickerCalendar]", ["react"], factory);
	else if(typeof exports === 'object')
		exports["rb_wixui.thunderbolt[DatePickerCalendar]"] = factory(require("react"));
	else
		root["rb_wixui.thunderbolt[DatePickerCalendar]"] = factory(root["React"]);
})((typeof self !== 'undefined' ? self : this), function(__WEBPACK_EXTERNAL_MODULE__5329__) {
return 