import DatePickerCalendarComponent from '@wix/thunderbolt-elements/src/components/DatePickerCalendar/viewer/DatePickerCalendar';


const DatePickerCalendar = {
  component: DatePickerCalendarComponent
};


export const components = {
  ['DatePickerCalendar']: DatePickerCalendar
};

